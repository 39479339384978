<template>
  <BeDynamic :page="page.page" :content="page.content" :type="backendLayout" />
</template>

<script>
import defaultPage from '~typo3/mixins/page/default'

export default {
  mixins: [defaultPage]
}
</script>
